import * as React from "react";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import GeneralTab from "../../components/HTabs";
import About from "../../components/About";
import Hero from "../../components/Hero";
import ImgContent from "../../components/GSTTemp/ImgContent";
import Document from "../../components/Document";
import Cta from "../../components/Cta";
// import Package from "../../components/GSTTemp/Package";

export default function IncomeTaxReturn() {
  // banner Slider
  const ItrSlider = [
    {
      header_color: false,
      content_color: false,
      marginTop: 40,
      background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
      header: `Online Income Tax Return Filing`,
      content: `Best Income Tax Return Registration Platform`,
      image: '/imgs/gifs/img1.gif',
      alt_tag: 'Best Online Income Tax Return Registration in Hosur',
    },
  ];

  /* Package Data */
  // const PackageCompData = {
  //   header: `Income Tax Return`,
  //   buyBtnLink: `#pricing-buy`,
  //   price: `6999`,
  //   content: [
  //     {
  //       points: `PAN & TAN Registration `,
  //       icon: "/imgs/icons/gst.png",
  //       alt_tag: "PAN & TAN Registration",
  //       background: "",
  //       plus: "+",
  //     },
  //     {
  //       points: `PF + ESIC + Professional Tax`,
  //       icon: "/imgs/icons/itr_filing.png",
  //       alt_tag: "TODAYFILINGS ITR E-filing service",
  //       background: "bg-f78acb",
  //       plus: "+",
  //       plus_dnone: "d-none d-md-block",
  //     },
  //     {
  //       points: `MSME Registration`,
  //       icon: "/imgs/icons/enterprises.png",
  //       alt_tag: "MSME Registration",
  //       background: "bg-eb6b3d",
  //       plus: "+",

  //      },
  //      {
  //        points: `Tax Consultancy Service `,
  //        icon: "/imgs/icons/consultant.png",
  //        alt_tag: "Tax Consultancy Service",
  //        background: "bg-c679e3",
  //      },
  //    ],
  //  }
  /* FaQ data */
  var ITReturnFAQ = [
    {
      header: 'What is ITR-V?',
      body: [
        {
          content: `After electronically documenting your income tax return, you receive
          an ITR-V, a one-page document. Within 120 days of e-documenting your
          government form, you must print, sign, and deliver it to the Income
          Tax Department.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is a balance sheet necessary for ITR Filing?',
      body: [
        {
          content: `Individuals and the HUFs that are carrying business or profession are required to file 
          the details of the assets and the liabilities through a balance sheet. It is necessary to file
           schedule AL while filing ITR which includes the taxpayer above Rs.50 lakh.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'Which IT return should I use for electronic filing?',
      body: [
        {
          content: ` For each type of tax scenario, there are nine structures: ITR 1, 2,
          2A, 3, 4, 4S, 5, 6 and 7. Choosing the appropriate annual tax
          paperwork takes a lot of effort. Because of this, if you are filing
          with TODAYFILINGS.com, we will determine the appropriate income tax
          return format.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can ITR be submitted online?',
      body: [
        {
          content: `ITR is applicable for the individuals and the HUFs that do not have any income 
          from business or profession. The ITR filing can be done online on the e-filing portal or in excel utility.`,
          icon: false,
        },
      ],
    },
    {
      header: `How would I make a government tax payment?`,
      body: [
        {
          content: `Using your net banking account and Challan 280, you can legally pay
          administration taxes on the Income Tax Department website.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: `Could I submit an ITR-1 with a rural wage that has been excused?`,
      body: [
        {
          content: ` If your agricultural salary is up to Rs 5,000, you can submit Form
          ITR 1. You must provide ITR 2 documentation for rural pay in excess
          of Rs 5,000.`,
          icon: false,
        },
      ],
    },
    {
      header: `How many parts is ITR divided into?`,
      body: [
        {
          content: `ITR is divided into 2 parts, Part A and B.`,
          icon: false,
        },
      ],
    },
    {
      header: `Could I submit an ITR-1 with a rural wage that has been excused?`,
      body: [
        {
          content: ` If your agricultural salary is up to Rs 5,000, you can submit Form
          ITR 1. You must provide ITR 2 documentation for rural pay in excess
          of Rs 5,000.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `Is it necessary to attach any archives to the salary return?`,
      body: [
        {
          content: `ITR return structures are less structured in terms of connections,
          thus a citizen is not required to submit any reports in conjunction
          with the arrival of payment (such as project verification, TDS
          declarations, etc). (regardless of whether documented physical or
          recorded electronically). However, these documents must be kept by
          the citizen and supplied to the duty professionals upon request in
          situations like evaluation, request, and so forth.`,
          icon: false,
        },
      ],
    },
    {
      header: `What does Part A of ITR include?`,
      body: [
        {
          content: `Part A of Form ITR includes all the general information.`,
          icon: false,
        },
      ],
    },
    {
      header: `What does Part B of Form ITR include?`,
      body: [
        {
          content: `Part B consists of the total income computation and the tax liability on the total income computation.`,
          icon: false,
        },
      ],
    },
  ];

  /* About Data Without Form*/
  const IscAboutData = {
    title: `Income Tax Return Filing`,
    sub_title: `Income tax return filing registration in just 7 Days`,
    paragraph: `The central government deducts income tax from each
    qualifying taxpayer. The process of submitting an income tax
    return, also known as an ITR, allows the taxpayer to claim
    tax deductions, calculate their net tax obligation, and keep
    track of their total taxable income. To avoid unnecessary
    disagreements, it is essential that people know how to
    submit income tax forms online in India and get assistance
    from professionals.`,
    header: `Our Features in Income Tax Return Filing in India`,
    points: [`UPI and net banking`, `RTGS/NEFT`, `Credit Card`],
  };
  /* Tabs Data */
  const IncomeTaxTabsData = {
    id: 'features',
    heading: 'Income Tax Filing Online in India Process',
    paragraph: '',
    body: [
      {
        tab_title: 'Documents',
        tab_content: [
          {
            content_title: `Check In The Event That You Need To Document A Tax
          Return`,
            content_paragraph: `For FY 2018-19, if your pay reaches Rs 2.5 lakh (Rs 3 lakh
            for those matured 60 years and more) you need document an
            income tax return in India. You should likewise record a
            tax return if extra TDS has been deducted and you need to
            ensure a refund. Paper tax return or electronic submission
            of taxes? You should file your income tax return online if
            you pay more than Rs 5 lakh in taxes or if you want a
            refund. When the individual taxpayer is 80 years of age or
            older, an exception is provided.`,
            points: [],
          },
          {
            content_title: `Accumulate Your Documents For Tax Filing`,
            content_paragraph: `Keep in mind that the income tax return has no
          annexure's. This means that your tax return should not be
          sent with any supporting documents. However, be careful
          when storing them. This will be useful in the event that
          the income tax division has any questions about your tax
          return.`,
            points: [
              ` Basic information, such as PAN, Aadhaar, and bank account
            information.`,
              `Paperwork that links your compensation, such as Form 16 or Form 16A.`,
            ],
          },
        ],
        content_img: '/imgs/business/registration.png',
        alt_img: 'TODAYFILINGS Income Tax Return Registration',
      },
      {
        tab_title: 'Form 16',
        tab_content: [
          {
            content_title: `Upload Your Form 16`,
            content_paragraph: [
              ` Every company in India issues Form 16 to each employee,
          often in the final seven days of May and the first
          fourteen days of June. They are available as two separate
          PDFs or in a single PDF.`,
              `All the information pertaining to the company's TAN and
            TDS is contained in Part A of Form 16. Important
            information concerning compensations, including HRA, LTA,
            your taxable wage, and deductions you have declared on the
            hour of investment accommodation, is contained in Part B.`,
              `To automatically review your income, deductions, and TDS
            and pre-fill your income tax filing, just upload them to
            TODAYFILINGS.com! Post Form 16 here. If you don't have a
            Form 16, you can still set up a tax return with just a
            paystub.`,
            ],
            points: [],
          },
          {
            content_title: `Claim Overdue Deductions and determine whether you need
          to make up any unpaid taxes.`,
            content_paragraph: `You can make a claim for any missed Section 80C expenses
          or deductions, including HRA and LIC premiums. LTA and
          medical reimbursements are not claimable. If you missed
          the deadline for submitting your investment proof in
          person, you can still claim your HRA, LIC premium, and any
          other Section 80C expenses or deductions when
          electronically filing your return with TODAYFILINGS.com.
          This will reduce your tax obligation. Please be aware that
          LTA and medical reimbursements cannot be claimed.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/digital.png',
        alt_img: 'Best Income Return Registration in Hosur',
      },
      {
        tab_title: 'ITR-V',
        tab_content: [
          {
            content_title: `On the screen, a 15-digit acknowledgment number will
          appear.`,
            content_paragraph: `You will receive a confirmation email with the 15-digit
          acknowledgement number from TODAYFILINGS and the income tax
          preparation department once you file your tax return
          online at TODAYFILINGS.`,
            points: [],
          },
          {
            content_title: `Send your income tax return by ITR-V or electronically.`,
            content_paragraph: `Within 120 days of the date of e-filing, you can e-verify
          your tax return online or send a one-page affirmation to
          the income tax department in Bangalore. With the majority
          of Net Banking accounts, this should be available. Make
          sure you verify the tax returns because the tax department
          does not handle returns that have not been verified.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/advance.png',
        alt_img: 'Best Income Tax Return Filing near Bangalore',
      },
      {
        tab_title: 'Payment',
        tab_content: [
          {
            content_title: `Track Your Refund If You Expect One.`,
            content_paragraph: `The e filing income tax department gets quicker every year
          with preparing your tax refunds. Taxpayers can expect to
          get their refund anyplace between only a little while for
          a couple of months.`,
            points: [],
          },
          {
            content_title: `If necessary, pay tax online at the department's
          website.`,
            content_paragraph: `As you fill up your tax return documentation, the tax is
          calculated. If you discover that you must pay additional
          tax, you should pay the tax first and then electronically
          file your tax return. Before July 31st, both of these should
          be completed. In the unlikely event that you fail to pay
          this tax by July 31st, you will be subject to interest
          charges of 1% per month (simple interest) on the unpaid tax
          balance. This interest will be calculated starting on the
          day that you must record the beginning of the applicable
          year until the day that you actually file your Indian income
          tax return.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/bank.png',
        alt_img: 'TODAYFILINGS Income Tax Return Payment Process',
      },
    ],
  };

  /* Img Content Component Data */
  const ItrIcData = {
    id: 'documents',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: `Why File Income Tax Returns?`,
    image: '/imgs/registration/dsc/dsc-doc.png',
    alt_tag: 'professional tax registration',
    points: [
      {
        head: 'Why is it necessary to keep records of your income tax returns?',
        content: `If your total Indian income exceeds Rs 2.5 lakh, you must file an
          income tax return, whether you are a resident Indian or an NRI.`,
        icon: true,
      },
      {
        head: '',
        content: `It is a good habit to document or file your income taxes electronically
          whether or not you fall within this restriction. An important
          record that you should start keeping as soon as you start
          making money from home is an income tax return filing online
          in India. This document reflects your financial stability and
          success while also demonstrating your capacity to pay back loans.`,
        icon: true,
      },
      {
        head: 'How might you prove an online income tax return filing in India?',
        content: `You can file
          your income tax returns online with us or through the website
          of the income tax department.`,
        icon: true,
      },
      {
        head: '',
        content: `With us, submitting an income tax return (ITR) is incredibly easy.
          Within 7 minutes, you can electronically file your taxes!`,
        icon: true,
      },
      {
        head: '',
        content: `Additionally, be aware that the deadline to electronically
          file your income tax return for the assessment year 2019–2020
          is earlier than July 31, 2019.`,
        icon: true,
      },
    ],
  };

  /* Document Section Data */
  const ItrDocData = {
    id: 'itr-doc',
    heading: `Income TAX Return Filing`,
    sub_heading: `Information required for e-filing your income tax returns`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-4`,
        delay: '100',
        img: `/imgs/icons/bregistration/pancard.png`,
        alt_tag: `Pan Card`,
        header: `PAN`,
        description: ` Produce critical information, such as your PAN and present location.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '200',
        img: `/imgs/icons/bregistration/aadhar.png`,
        alt_tag: `Aadhar Card`,
        header: `Aadhar Card`,
        description: `Produce critical information, such as an Aadhar Card and
        your current location.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '300',
        img: `/imgs/icons/bregistration/nominee.png`,
        alt_tag: `Verification`,
        header: `Verification`,
        description: `Verifications from businesses such FDs and savings
          accounts, among other things.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '400',
        img: `/imgs/icons/it/business.png`,
        alt_tag: `Section 80`,
        header: `Section 80`,
        description: `Information regarding every deduction you are eligible for
        under Section 80.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '500',
        img: `/imgs/icons/it/stock.png`,
        alt_tag: `Tax Instalment`,
        header: `Tax Instalment`,
        description: `Tax instalment information, such as TDS and payments made in
        advance of taxes.`,
      },
      {
        col: `col-lg-4 col-md-4`,
        delay: '600',
        img: `/imgs/icons/bregistration/bank.png`,
        alt_tag: `Bank Accounts`,
        header: `Bank Accounts`,
        description: `Accounts that you provide information about every bank
        account you have had in the specified fiscal year.`,
      },
    ],
  };

  return (
    <div>
      <Seo
        title='Income Tax Return'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          <Hero sliders={ItrSlider} />

          <About about_data={IscAboutData} />

          <Counter />

          <ImgContent item={ItrIcData} />

          <Document docSecData={ItrDocData} />

          <GeneralTab HTabsData={IncomeTaxTabsData} />

          <Cta />
          <FAQAccord items={ITReturnFAQ} />
        </main>
      </Layout>
    </div>
  );
}
